<template>
	<div></div>
</template>

<script>
import ServiceObjectMixin from "@mixins/service/ServiceObjectMixin.vue"
export default {
	mixins : [ServiceObjectMixin] ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
	} ,
	watch : {
		service : {
			handler() {
				this.process()
			} ,
			deep : true
		} ,
		$route(newVal) {
			this.process()
		}
	} ,
	mounted() {
		this.process()
	} ,
	methods : {
		process() {
			if (this.canViewDetail) {
				const params = {id : this.service.id};
				switch(this.service.status) {
					case 'in_progress' :
						this.$router.replace({name : 'service/service_job',params})
						return
					case 'pre_delivery' :
						if (this.canViewPreDelivery) {
							this.$router.replace({name : 'service/pre_delivery',params})
							return
						}
						break
					case 'wait_delivery' :
						this.$router.replace({name : 'service/return',params})
						return
					case 'close' :
						if (this.canViewPayment) {
							this.$router.replace({name : 'service/payment',params})
							return
						} else if (this.canViewReturn) {
							this.$router.replace({name : 'service/return',params})
							return
						}
						break
				}
				this.$router.replace({name : 'service/pre_service',params})
			}
		}
	}

}
</script>
